import React from 'react';
import { FormProvider, useForm } from "react-hook-form";

let Form = React.forwardRef((props, ref) => {
    const formMethods = useForm({mode: (props.mode != undefined ? props.mode : 'onChange'), defaultValues: (props.defaultValues != undefined ? props.defaultValues : {})});
    React.useImperativeHandle(ref, () => ({
        reset: formMethods.reset,
        clearErrors: formMethods.clearErrors,
        setValue: formMethods.setValue,
        getValues: formMethods.getValues,
        trigger: formMethods.trigger,
        errors: formMethods.errors,
        handleSubmit: formMethods.handleSubmit
    }));

    const handleSubmit = (formData) => {
        if(props.onSubmit !== undefined){
            let newFormData = {}
            Object.entries(formData).forEach(([key, value]) => {
                newFormData[key] = (value === "" ? undefined : value);
            });
            props.onSubmit(newFormData);
        }
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={ formMethods.handleSubmit(handleSubmit) }>
                {props.children}
            </form>
        </FormProvider>
    );
});

export { Form };