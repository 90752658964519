import React from 'react';
import { useFormContext, useWatch } from "react-hook-form";
import { useIsMounted } from '~/components/hooks/useIsMounted';
var _ = require('lodash');

const PasswordInput = React.forwardRef((props, ref) => {
    React.useImperativeHandle(ref, () => ({
        getValue: () => { return inputValue },
        setValue: setInputValue,
        setDisabled,
        setReadOnly,
        focus: () => { inputRef.current.focus() }
    }));

    let register, unregister, setValue, errors, watch;
    if(useFormContext() !== null){
        let context = useFormContext();
        register = context.register;
        unregister = context.unregister;
        setValue = context.setValue;
        errors = context.errors;
        watch = useWatch({
            name: props.name
        });
    }

    const [ disabled, setDisabled ] = React.useState((props.disabled == true ? true : false));
    const [ readOnly, setReadOnly ] = React.useState((props.readOnly == true ? true : false));
    const [ inputValue, setInputValue ] = React.useState((props.value !== undefined ? props.value : ""));
    const isMounted = useIsMounted();
    const isMountedInstance = isMounted();
    const inputRef = React.useRef();

    React.useEffect(() => {
        return () => {
            if(unregister !== undefined){
                unregister(props.name);
            }
        }
    }, []);

    React.useEffect(() => {
        if(disabled !== true){
            if(register !== undefined){
                register({name: props.name}, getValidations());
            }
        }
    }, [ register ])

    React.useEffect(() => {
        if(disabled !== true){
            if(watch !== undefined){
                if(watch !== inputValue){
                    setInputValue(watch);
                }
            }else{
                if(isMountedInstance){
                    setInputValue("");
                }else{
                    if(setValue !== undefined){
                        setValue(props.name, inputValue);
                    }
                }
            }
        }
    }, [ watch ])

    React.useEffect(() => {
        if(_.get(errors, props.name, undefined) !== undefined){
            inputRef.current.focus();
        }
    }, [ errors ]);

    React.useEffect(() => {
        if(isMountedInstance){
            if(disabled !== true){
                if(register !== undefined){
                    register({name: props.name}, getValidations());
                    setValue(props.name, inputValue);
                }
            }else{
                if(unregister !== undefined){
                    unregister(props.name);
                }
            }
        }
    }, [ disabled ]);

    React.useEffect(() => {
        if(isMountedInstance){
            if(register !== undefined){
                register({name: props.name}, getValidations());
            }
        }
    }, [ props.required ]);

    React.useEffect(() => {
        if(isMountedInstance){
            if(props.disabled == true){
                setDisabled(true);
            }else{
                setDisabled(false);
            }
        }
    }, [ props.disabled ]);

    React.useEffect(() => {
        if(props.readOnly == true){
            setReadOnly(true);
        }else{
            setReadOnly(false);
        }
    }, [ props.readOnly ]);

    React.useEffect(() => {
        if(isMountedInstance){
            if(props.value !== undefined){
                setInputValue(props.value);
            }else{
                setInputValue("");
            }
        }
    }, [ props.value ]);

    React.useEffect(() => {
        if(isMountedInstance){
            if(disabled !== true){
                if(setValue !== undefined){
                    if(watch !== inputValue){
                        if(watch === undefined){
                            setValue(props.name, inputValue);
                            
                        }else{
                            setValue(props.name, inputValue, { shouldValidate: true });
                        }
                    }
                }
            }

            if(props.onChange !== undefined){
                props.onChange(inputValue);
            }
        }
    }, [ inputValue ]);

    const getValidations = () => {
        let validations = {
            validate: {}
        };
        
        if(props.required !== undefined){
            let errorMessage = null;
            if(typeof(props.required) == "object"){
                if(props.required.message !== undefined){
                    errorMessage = props.required.message;
                }
            }
            validations.required = {
                value: true,
                message:  (errorMessage !== null ? errorMessage : 'Required')
            }
        }

        if(props.minLength !== undefined){
            let errorMessage = null;
            let minLength = null;
            if(typeof(props.minLength) == "object"){
                if(props.minLength.value !== undefined){
                    minLength = props.minLength.value;
                }
                if(props.minLength.message !== undefined){
                    errorMessage = props.minLength.message;
                }
            }else{
                minLength = props.minLength;
            }
            if(minLength !== null){
                validations.validate.minLength = (value) => (
                     ( value === "" || value === null || value.length >= minLength ) ||  (errorMessage !== null ? errorMessage : "Must have "+minLength+" or more characters")
                )
            }
        }

        if(props.maxLength !== undefined){
            let errorMessage = null;
            let maxLength = null;
            if(typeof(props.maxLength) == "object"){
                if(props.maxLength.value !== undefined){
                    maxLength = props.maxLength.value;
                }
                if(props.maxLength.message !== undefined){
                    errorMessage = props.maxLength.message;
                }
            }else{
                maxLength = props.maxLength;
            }
            if(maxLength !== null){
                validations.validate.maxLength = (value) => (
                     ( value === "" || value === null || value.length <= maxLength ) ||  (errorMessage !== null ? errorMessage : "Must have "+maxLength+" or less characters")
                )
            }
        }
        
        return validations;
    }

    return(
        <div className="input" attr-disabled={ ( disabled == true ? 'true' : 'false') } attr-validated={ (_.get(errors, props.name, undefined) != undefined ? 'false' : 'true') }>
            <style jsx>{`
                .input{
                    position: relative;
                    width: ${(props.width != undefined ? props.width : "200px")};
                    margin: ${(props.margin != undefined ? props.margin : "0 20px 0 0")};
                }
                .input input{
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 13px;
                    padding-top: 18px;
                    padding-bottom: 4px;
                    padding-left: 10px;
                    color: #858383;
                    background-color: #fff;
                    font-weight: 400;
                    border: 1px solid #d4d8dc;
                    border-radius: 5px;
                    transition: all .15s ease;
                }
                .input[attr-validated="false"] input{
                    border: 1px solid #F44336;
                }
                .input[attr-validated="false"] .label{
                    color: #F44336;
                }
                .input .validation-error-message{
                    color: #F44336;
                    font-size: 12px;
                    font-weight:bold;
                    margin-top: 2px;
                }
                .input input:focus{
                    outline:0; 
                }
                .input input:not([readOnly]):focus + .label, .input input:not(:placeholder-shown) + .label{
                    font-size: 12px;
                    font-weight: bold;
                    position: absolute;
                    left: 12px;
                    top: 5px;
                    transition: all .15s ease
                }
                .label{
                    font-size: 12px;
                    color: #858383;
                    position: absolute;
                    left: 12px;
                    top: 14px;
                    cursor: text;
                    text-transform: uppercase;
                    pointer-events: none;
                }
                .input[attr-disabled="true"] .label{
                    color: #d0d0d0;
                }
                .input[attr-disabled="true"] input{
                    color: #d0d0d0;
                }
            `}</style>
            <input type="password" ref={ inputRef } name={ props.name } placeholder=" " onChange={ (e) => { setInputValue(e.target.value) } } value={ inputValue } disabled={ disabled } readOnly={ readOnly }/>
            <div className="label">{props.placeholder}</div>
            {(() =>{
                if(_.get(errors, props.name, undefined) != undefined){
                    return (<div className={`validation-error-message`}>{ _.get(errors, props.name+".message", undefined) }</div>);
                }
            })()}
        </div>
    )
});

export { PasswordInput }