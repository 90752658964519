import React from 'react';
import Router, { useRouter } from "next/router";
import cookies from 'next-cookies'
import { Api } from '~/api/Api';
import { Form } from '~/components/form/Form';
import { TextInput } from '~/components/form/TextInput';
import { PasswordInput } from '~/components/form/PasswordInput';
import { SubmitButton } from '~/components/form/SubmitButton';

const LoginPage = (props) => {
    const [ showWrongCredentialsAlert, setShowWrongCredentialsAlert ] = React.useState(false);
    const router = useRouter();

    React.useEffect(() => {
        let returnUrl = "";
        if(props.route.query.returnUrl !== undefined){
            returnUrl = props.route.query.returnUrl;
        }
        if(props.route.query.PropertyAdministratorId){
            if(props.route.query.PropertyAdministratorId != props.cookies.PropertyAdministratorId){
                let cookieDate = new Date;
                cookieDate.setFullYear(cookieDate.getFullYear() +10);
                document.cookie = 'PropertyAdministratorId='+props.route.query.PropertyAdministratorId+'; expires=' + cookieDate.toGMTString() + ';';
            }
        }else{
            if(props.cookies.PropertyAdministratorId){
                if(returnUrl != ""){
                    Router.push('/login?PropertyAdministratorId='+props.cookies.PropertyAdministratorId+'&returnUrl='+returnUrl);

                }else{
                    Router.push('/login?PropertyAdministratorId='+props.cookies.PropertyAdministratorId);
                }
            }
        }
    }, [])
    const handleLoginFormSubmit = (formData) => {
        setShowWrongCredentialsAlert(false);
        let data = { username: formData.username, password: formData.password };
        Api.post('login?PropertyAdministratorId='+router.query.PropertyAdministratorId, data).then((response) => {
            if(response.status == 200){
                let size = Math.floor(response.json.token.length/2);
                let token1 = response.json.token.substring(0, size);
                let token2 = response.json.token.substring(size, response.json.token.length);
                document.cookie = `token1=${token1}; path=/`;
                document.cookie = `token2=${token2}; path=/`;
                if(props.route.query.returnUrl !== undefined){
                    Router.push({pathname: props.route.query.returnUrl});
                }else{
                    Router.push({pathname: '/dashboard'});
                }
            }else{
                setShowWrongCredentialsAlert(true);
            }
        })
    }
    return(
        <div className="page-container">
            <style jsx>{`
                .page-container{
                    display:flex;
                    height: 100%;
                    min-height: 100vh;
                }
                .login-section, .img-section{
                    width:50%;
                }
                .header{
                    width: 100%;
                    box-sizing: border-box;
                    display:flex;
                    background-color: #fff;
                    padding: 20px 40px 0px 40px;
                    margin-bottom: 35px;
                }
                .logo{
                    height: 90px;
                    display: flex;
                    align-items: center;
                }
                .logo img{
                    height: 100%;
                }
                .website-link{
                    flex: 1;
                    display:flex;
                    justify-content:flex-end;
                    margin-top: 15px;
                }
                .website-link a{
                    font-size: 15px;
                    color:#686565;
                    text-decoration:none;
                    font-weight: 400;
                }
                .website-link a:hover{
                    text-decoration:underline;
                }
                .titles{
                    display:flex;
                    justify-content: center;
                    width: 100%;
                    flex-wrap: wrap;
                }
                .title-1{
                    width: 100%;
                    color: #686565;
                    font-weight: bold;
                    font-size: 18px;
                    text-align: center;
                }
                .title-2{
                    width: 100%;
                    color: #686565;
                    font-weight: 300;
                    font-size: 22px;
                    text-align: center;
                }
                .title-3{
                    width: 100%;
                    color: #686565;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    margin-top: 20px;
                }
                .form-container{
                    min-width: 250px;
                    width: 400px;
                    margin: 45px auto;
                    padding: 0px 20px;
                    box-sizing:border-box;
                }
                .login-img{
                    background: url(images/login-img.jpg) no-repeat center;
                    width: 100%;
                    height: 100%;
                    min-height: 100vh;
                    background-size: cover;
                }
                .wrong-credentials-alert{
                    margin-bottom: 20px;
                    text-align: center;
                    margin-top: 10px;
                    color: #F44336;
                    font-size: 14px;
                    font-weight: 500;
                }
                .hide{
                    display:none;
                }
                @media (max-width: 800px) {
                    .login-section{
                        width:100%;
                    }
                    .img-section{
                        width:0%;
                    }
                }
            `}</style>
            <div className="login-section">
                <div className="header">
                    <div className="logo">
                        <img src={"/images/"+( props.route.query.PropertyAdministratorId == 1 ? 'logo.png' : (props.route.query.PropertyAdministratorId == 2 ? 'logo2.png' : ''))}/>
                    </div>
                    <div className="website-link">
                        <a href={( props.route.query.PropertyAdministratorId == 1 ? 'https://albagoestates.com/' : (props.route.query.PropertyAdministratorId == 2 ? 'https://octosync.mx' : ''))}>Website</a>
                    </div>
                </div>
                <div className="titles">
                    <div className="title-1">WELCOME TO</div>
                    <div className="title-2">{( props.route.query.PropertyAdministratorId == 1 ? 'ALBAGO ESTATES' : (props.route.query.PropertyAdministratorId == 2 ? 'OCTOSYNC' : ''))}</div>
                    <div className="title-3">ACCESS WITH YOUR USERNAME AND PASSWORD</div>
                </div>
                <div className="form-container">
                    {(() => {
                        if(showWrongCredentialsAlert){
                            return (
                                <div className="wrong-credentials-alert">Wrong username or password</div>
                            )
                        }
                    })()}
                    <Form onSubmit={handleLoginFormSubmit}>
                        <TextInput name="username" placeholder="Username" width="100%" margin="0 0 20px 0" required/>
                        <PasswordInput name="password" placeholder="Password" width="100%" margin="0 0 20px 0" required/>
                        <SubmitButton width="100%">Login</SubmitButton>
                    </Form>
                </div>
            </div>
            <div className="img-section">
                <div className="login-img"></div>
            </div>
        </div>
    )
}
LoginPage.getInitialProps = async (context) =>  {
    let token1 = cookies(context).token1;
    let token2 = cookies(context).token2;
    if(token1 !== undefined && token2 !== undefined){
        context.res.writeHead(302, {
            Location: '/dashboard'
        });
        context.res.end();
    }
    let route = { pathname: context.pathname, query: context.query, asPath: context.asPath }
    return { route, cookies: cookies(context) };
}

export default LoginPage;